<template>
  <div id="page-container" v-loading="loadingPayment"></div>
</template>

<script>
import { useCommonStore } from "@/stores/CommonStore.ts";
import { usePaymentStore } from "@/stores/PaymentStore.ts";
import { mapActions } from "pinia";
export default {
  name: "PaymentPage",
  props: {
    sample_id: String,
    accession_id: String,
    jwtToken: String,
    charge_type_id: String,
    charge_type: String,
    concierge_needed: String,
    direct_to_blooddraw: String,
    amount_to_pay: Number,
    count: Number,
    always_show_payment:String,
  },
  async mounted() {
    this.loadingPayment = true;
    this.setSampleId(this.sample_id);
    this.setAccessionId(this.accession_id);
    this.setSampleIdViaPaymentStore(this.sample_id);
    this.setAccessionIdViaPaymentStore(this.accession_id);
    this.setChargeTypeId(this.charge_type_id);
    this.setChargeType(this.charge_type);
    this.setJwtToken(this.jwtToken);
    this.setConciergeNeeded(this.concierge_needed);
    this.setDirectToBloodDraw(this.direct_to_blooddraw);
    this.setFixedAmountToPay(this.amount_to_pay);
    this.setCount(this.count);
    this.setAlwaysShowPayment(this.always_show_payment);
    //call backend to get paymentInfo status
    try{
      const paymentStatus =  await this.getAllPaymentInfo();
      this.loadingPayment = false;
      // if order is cancelled, push to order_cancelled page
      if (paymentStatus == 4) {
        this.$router.push("/order_cancelled");
      } else if (paymentStatus == 2) {
        this.$router.push("/payment_failed");
      } else if (paymentStatus == 1) {
        this.$router.push("/payment_pending");
      } else {
        this.$router.push("/payment_form");
      }
    }catch(err){
      this.loadingPayment = false;
      this.$router.push("/failed");
    }
  },
  data(){
    return{
      loadingPayment:false,
    }
  },
  methods: {
    ...mapActions(useCommonStore, ["setSampleId", "setAccessionId"]),
    ...mapActions(usePaymentStore, [
      "setPaymentSampleId",
      "setPaymentAccessionId",
      'setSampleIdViaPaymentStore',
      'setAccessionIdViaPaymentStore',
      "setChargeTypeId",
      "setChargeType",
      "setJwtToken",
      "getPaymentStatus",
      "setConciergeNeeded",
      "setDirectToBloodDraw",
      "setFixedAmountToPay",
      "setCount",
      'setAlwaysShowPayment',
    ]),
    async getAllPaymentInfo() {
      try {
        const paymentStatus = await this.getPaymentStatus();
        return Promise.resolve(paymentStatus);
      } catch (error) {
        console.error("failed to retrieve payment status", error);
        return Promise.reject(error);
      }
    },
  },
};
</script>
